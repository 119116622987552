<template>
  <v-app-bar app elevation="0" color="#FFFFFF">
    <v-col style="position: relative">
      <v-img src="../assets/images/signosBrand.png" contain
             :height="this.$vuetify.breakpoint.xsOnly ? '100px':'130px'"
             :width="this.$vuetify.breakpoint.xsOnly ? '140px':'170px'"
             style="position: absolute; top:-5px; right: 30px"
      />
    </v-col>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'AppBar'
  }
</script>

<style scoped>

</style>
