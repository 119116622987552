<template>
    <v-footer app absolute color="#204A6C" style="z-index: 10">
        <v-container fluid>
            <v-row>
                <v-col offset="0" offset-md="4" cols="12" sm="8" md="4" class="d-flex justify-center justify-md-start align-center">
                    <p
                            class="text-center ma-0 white--text font-weight-bold text-sm-subtitle-1 text-md-body-1">
                        Expertos
                        en Cultura y Liderazgo</p>
                </v-col>
                <v-fade-transition appear>
                    <v-col v-if="!this.$vuetify.breakpoint.xsOnly" offset="0" cols="12" sm="4" md="3"
                           class="d-flex justify-start-sm align-end">
                        <div class="d-flex align-center justify-center">
                            <v-btn
                                    class="mr-5"
                                    fab
                                    dark
                                    x-small
                                    color="#FFFFF"
                                    outlined
                                    href="https://www.linkedin.com/company/signos-project-consulting"
                                    target="_blank"
                            >
                                <v-icon dark @mouseover="showKnowUs = true" @mouseleave="showKnowUs = false">
                                    mdi-linkedin
                                </v-icon>
                            </v-btn>
                            <!--                        <v-dialog v-model="emailPopup" width="500px" class="pa-0" persistent>-->
                            <!--                            <EmailDialog @closeEmailPopup="emailPopup=false"></EmailDialog>-->
                            <!--                        </v-dialog>-->
                            <v-fade-transition appear>
                                <p v-show="!this.$vuetify.breakpoint.xsOnly && showKnowUs"
                                   class="text-center ma-0 white--text font-weight-bold text-body-1">Conocenos</p>
                            </v-fade-transition>
                        </div>
                    </v-col>
                </v-fade-transition>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
  import EmailDialog from './EmailDialog'

  export default {
    name: 'Footer',
    components: { EmailDialog },
    data () {
      return {
        emailPopup: false,
        showKnowUs: false,
      }
    },
  }
</script>

<style scoped>
</style>
