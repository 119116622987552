<template>
  <v-app style="position: relative">
    <v-img class="ma-0 pa-0 pl-4" style="position: absolute; z-index: 6" height="100%" width="30%" src="../assets/images/misc.png"/>
    <AppBar></AppBar>
    <v-main class="elevation-3">
      <v-fade-transition appear>
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
  import AppBar from '../components/AppBar'
  import Footer from '../components/Footer'

  export default {
    name: 'LayoutDefault',
    components: {
      AppBar,
      Footer
    }
  }
</script>

<style scoped>

</style>
