<template>
  <v-container fluid class="fill-height d-flex justify-center pa-0">
    <v-card height="500px" width="500px" class="pa-5">
      <v-form>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="email"
            label="Email"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="subject"
            label="Subject"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-textarea
            height="150px"
            outlined
            v-model="message"
            label="Message"
            required
          ></v-textarea>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-around align-center"
        >
          <v-btn
            class="mr-5"
            dark
            large
            color="red"
            outlined
            width="130px"
            @click="closeEmailPopup"
          >
            <v-icon dark left>
              mdi-cancel
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="mr-5"
            dark
            large
            color="#7aa34e"
            outlined
            width="130px"
          >
            <v-icon dark left>
              mdi-email-send-outline
            </v-icon>
            Enviar
          </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'EmailDialog',
    data () {
      return {
        email: '',
        message: '',
        subject: ''
      }
    },
    methods: {
      closeEmailPopup(){
        this.$emit("closeEmailPopup");
      }
    }
  }
</script>

<style scoped>

</style>
